import { UserRoles } from '../plugins/constants';

export default [
    {
        path: 'chatting-test-view',
        component: () => import(/* webpackChunkName: "chat-layout"*/'../views/chat-view-test/Layout.vue'),
        name: 'ChatManagement',
        redirect: { name: 'ChatScreen' },
        children: [
            {
                path: 'management/advisor-:advisor_id/to/job-:job_seeker_id',
                name: 'ChatScreen',
                meta: {
                    title: 'Chat (testing)',
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor, UserRoles.jobSeeker ],
                },
                component: () => import(/* webpackChunkName: "chat-view" */'../views/chat-view-test/Chat.vue')
            },
        ]
    }
]
