import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'calendar',
        component: () => import(/* webpackChunkName: "calendar-layout"*/'../views/calendar/Layout.vue'),
        name: 'Calendar',
        redirect: { name: 'CalendarManagement' },
        children: [
            {
                path: 'management',
                name: 'CalendarManagement',
                meta: {
                    title: i18n.t("menu.calendar"),
                    requireAuth: true,
                    permissions: [ UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "calendar-management-view" */'../views/calendar/Management.vue')
            },
        ]
    }
]
