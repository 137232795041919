import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'management',
        component: () => import(/* webpackChunkName: "admin-management-layout"*/'../views/admin-management/Layout.vue'),
        name: 'AdminManagement',
        meta: {
            requireAuth: true,
            permissions: [ UserRoles.systemAdmin, UserRoles.admin ]
        },
        children: [
            {
                path: 'search',
                name: 'SearchAdmin',
                meta: {
                    title: i18n.t("menu.admin_management"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin],
                },
                component: () => import(/* webpackChunkName: "search-admin-view" */'../views/admin-management/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateAdmin',
                meta: {
                    title: i18n.t("menu.admin_management"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin ],
                },
                component: () => import(/* webpackChunkName: "create-admin-view" */'../views/admin-management/Create.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditAdmin',
                meta: {
                    title: i18n.t("menu.admin_management"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin ],
                },
                component: () => import(/* webpackChunkName: "edit-admin-view" */'../views/admin-management/Edit.vue')
            }
        ],
    }
];
