import Auth from './auth';
import Panel from './panel';
import User from './user';
import Agent from './agent';
import AdminManage from './admin-manage';
import Account from './account';
import Advisor from './advisor';
import Calendar from './calendar';
import JobSeeker from './job-seeker';
import Menu from './menu';
import System from './system';
import Role from './role';
import Notification from './notification';
import SalesManagement from './sales-management';
import Payment from './payment';
import Project from './project';

export default {
    "auth": Auth,
    "panel": Panel,
    "user": User,
    "agent": Agent,
    "admin": AdminManage,
    "account": Account,
    "calendar": Calendar,
    "advisor": Advisor,
    "jobseeker": JobSeeker,
    "menu": Menu,
    "system": System,
    "role": Role,
    "notification": Notification,
    "sales_management": SalesManagement,
    "payment": Payment,
    "project": Project
}
