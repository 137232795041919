import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'agents',
        component: () => import(/* webpackChunkName: "agent-layout"*/'../views/agent/Layout.vue'),
        name: 'AgentManagement',
        redirect: { name: 'SearchAgent' },
        children: [
            {
                path: 'search',
                name: 'SearchAgent',
                meta: {
                    title: i18n.t("menu.agent"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin ],
                },
                component: () => import(/* webpackChunkName: "search-agent-view" */'../views/agent/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateAgent',
                meta: {
                    title: i18n.t("menu.agent"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin ],
                },
                component: () => import(/* webpackChunkName: "create-agent-view" */'../views/agent/Create.vue')
            },
            {
                path: 'edit/:agentId',
                name: 'EditAgent',
                meta: {
                    title: i18n.t("menu.agent"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin ],
                },
                component: () => import(/* webpackChunkName: "edit-agent-view" */'../views/agent/Edit.vue')
            }
        ]
    }
];
