export default {
    label_search: "検索",
    label_reset: "クリア",
    label_no_data: "表示する情報がありません",
    label_please_choose: "選択してください",
    label_register: "登録",
    label_go_back: "戻る",
    label_create: "新規登録",
    label_confirm_text_save: "登録します。よろしいですか？",
    label_confirm_text_remove: "削除します。よろしいですか？",
    label_confirm_title: "確認",
    label_edit: "編集",
    label_remove: "削除",
    label_delete_btn_modal: "削除する",
    label_cancel_btn_modal: "キャンセル",
    label_login: "ログイン",
    label_create_success: "登録しました。",
    label_update_success: "更新しました。",
    label_delete_success: "削除しました。",
    label_link_success: "連携しました。",
    label_unlink_success: "解除しました。",
    label_update_fail: "更新できません。内容をご確認ください。",
    label_delete_fail: "削除できません。内容をご確認ください。",
    label_create_fail: "登録できません。内容をご確認ください。",
    label_link_fail: "連携できません。もう1度試してください。",
    label_unlink_fail: "解除できません。もう1度試してください。",
    label_retry_link_fail: "現在連携中とは異なるGoogleアカウントです。アカウントを変更する場合は1度連携を解除してください。",
    label_google_api_fail_400: "Googleアカウントを再連携してください。",
    label_google_api_fail_404: "連携されているGoogleアカウント内にミーティングメールアドレスが存在しません。連携解除後に連携し直してください。",
    label_google_api_fail_other: "Googleカレンダーのスケジュール取得時に、エラーが発生しました。",
    label_google_link: "Googleカレンダー連携",
    label_google_unlink: "Googleカレンダー連携解除",
    label_google_retry_link: "Googleカレンダー再連携",
    label_fail: "失敗しました。",
    label_failed_validation: "更新できません。データを再度確認してください。",
    label_all: "全て",
    label_index: "No.",
    label_place_holder: "入力してください",
    label_action: "操作",
    click_to_link_google: "メールアドレスがGoogle カレンダーに連携されていません。「Google カレンダー連携」ボタンをクリックして完了してください。",
}
