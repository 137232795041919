import moment from 'moment';
import Calendarservice from '../services/calendar.service';
import store from "./index";

const formatEvent = (event) => ({
    ...event,
    start: moment(`${event.date} ${event.start_time}`).format('YYYY-MM-DD HH:mm:ss'),
    end:  moment(`${event.date} ${event.end_time}`).format('YYYY-MM-DD HH:mm:ss'),
    backgroundColor: event.created_by_system_flag == 'google' ? '#d3d3d3' : '#007bff',
    textColor: 'white',
    extendedProps: {
        content: event.content,
        message: event.message
    }
})

const state = {
    events: [],
    loading: false,
    from: '',
    to: '',
};
const getters = {
    getEvents: (state) => {
        return state.events
    },
    getLoading: (state) => state.loading,
};
const mutations = {
    PUSH_EVENT: (state, events) => {
        state.events = [...state.events, ...events];
        state.events = _.uniqBy(state.events, 'id');
    },
    UPDATE_EVENTS: (state, event) => {
        event = {
            ...event,
            start: moment(`${event.date} ${event.start_time}:00`).format('YYYY-MM-DD HH:mm:ss'),
            end:  moment(`${event.date} ${event.end_time}:00`).format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: '#007bff',
            textColor: 'white',
            extendedProps: {
                content: event.content,
                message: event.message
            }
        }
        state.events = state.events.filter(item => item.id !== event.id);
        state.events = [...state.events, event];
    },
    ADD_EVENT: (state, event) => {
        event = {
            ...event,
            start: moment(`${event.date} ${event.start_time}`).format('YYYY-MM-DD HH:mm:ss'),
            end:  moment(`${event.date} ${event.end_time}`).format('YYYY-MM-DD HH:mm:ss'),
            backgroundColor: '#007bff',
            textColor: 'white',
            extendedProps: {
                content: event.content,
                message: event.message
            }
        };
        state.events = [...state.events, event];
    },

    REMOVE_EVENT: (state, eventId) => {
        state.events = state.events.filter(item => item.id != eventId);
    },

    REFRESH_ALL_EVENT: (state) => {
        state.events = [];
    }
};
const actions = {
    async getEventsInterview({state, commit}, payload) {
        let { from, to } = payload;
        state.from = from;
        state.to = to;
        // call api request to get events
        state.loading = true;
        try {
            let response = await Calendarservice.getListEventInterviewSchedule({from, to});
            state.loading = false;
            let listEvent = response.list.map(item => formatEvent(item));
            if (response.errorType === '400') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailed400');
            }
            if (response.errorType === '404') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailed404');
            }
            if (response.errorType === 'other') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailedOther');
            }
            commit('PUSH_EVENT', listEvent);
        } catch (err) {
            state.loading = false;
            if (err.data === '400') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailed400');
            }
            if (err.data === '404') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailed404');
            }
            if (err.data === 'other') {
                window.scroll({top: 0, behavior: 'smooth'});
                store.dispatch('GoogleApiFailedOther');
            }
        }


        // let maxRecord = 5;
        // let list = [];

        // for (let i = 1; i <= maxRecord; i++) {
        //     let randomId = Math.floor(Math.random() * 100000);
        //     let randomDay = Math.floor(Math.random() * 10);
        //     let randomHour = Math.floor(Math.random() * 18);
        //     let randomRange = Math.floor(Math.random() * 4)
        //     list.push({
        //         id: randomId,
        //         title: 'Event here! - ' + randomId ,
        //         allDay: false,
        //         start: `${moment().add(randomDay, 'days').format('YYYY-MM-DD')} ${randomHour}:00:00`,
        //         end:  `${moment().add(randomDay, 'days').format('YYYY-MM-DD')} ${randomHour + randomRange}:00:00`,
        //         description: 'Description data ' + randomId,
        //         backgroundColor: 'blue'
        //     })
        // }
        // commit('PUSH_EVENT', list);
    },

    async loadEvent({state, commit}) {
        try {
            let response = await Calendarservice.getListEventInterviewSchedule({
                from: state.from,
                to: state.to
            });
            state.loading = false;
            console.log(response);
            let listEvent = response.list.map(item => formatEvent(item));
            commit('PUSH_EVENT', listEvent);
        } catch (err) {
            state.loading = false;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
