<template>
    <p class="h2 font-weight-bold col-12 mb-2 mt-2">{{ getTitleofPage }}</p>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "TitleOfPage",
    computed: {
        ...mapGetters('Auth', ['isAdvisor']),
        getTitleofPage() {
            if (this.$route.name == 'EditAdvisor' && this.isAdvisor)
            {
                return this.$t('menu.advisor')
            }
            return this.$route.meta.title ?? '';
        }
    },
}
</script>
