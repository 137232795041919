import ApiGateway from "../plugins/gateways";
import HttpClient  from "../plugins/httpClient";

var httpClient = new HttpClient(ApiGateway.localhost)
const prefix = '/chat';

export default {
    getMessage: (query) => httpClient.get(`${prefix}/messages`, query, {}),
    getListMessage: (query) => httpClient.get(`${prefix}/messages/list`, query, {}),
    sendMessage: (body) => httpClient.post(`${prefix}/messages`, {}, body),
    deleteMessage: (id) => httpClient.delete(`${prefix}/messages/${id}`),
    getChatNotification:() => httpClient.get(`${prefix}/messages/getChatNotification`),
    messageHasSeen: (body) => httpClient.post(`${prefix}/messages/seen`, {}, body),
}
