export default {
    label_status: "Status",
    label_advisor: "Advisor",
    label_advisor_name: "Advisor name",
    label_decision_rate: "Decision rate",
    label_evaluation: "Evaluation",
    label_active: "Active",
    label_inactive: "Inactive",
    label_create_advisor: "Create new advisor",
    label_advisor_email: "Email address",
    label_advisor_password: "Password",
    label_advisor_age: "Age",
    label_advisor_tel: "Telephone number",
    label_advisor_education: "Education",
    label_advisor_feature: "Features / appeal points",
    label_advisor_zooom_account: "Zoom account",
    label_advisor_calendar_id: "Calendar ID",
    label_advisor_face_photo: "Face photo",
    label_advisor_pr_video: "PR video",
    label_advisor_decision_rate: "Decision rate",
    label_advisor_user_evaluation: "User evaluation",
    label_google_link: "Link Google Calendar. \n Is it OK?",
    label_google_unlink: "Unlink Google Calendar. \n Delete linked events from calendar management. \n Is it OK?",
    label_google_retry_link: "Re-connect Google Calendar. \n Please specify the account to be re-linked \n as the same account as when the linkage was made.  Is it OK?",
    label_google_unlink_button: "Unlink",
    label_google_retry_link_button: "Re-connect",
}
