var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container position-relative chat-container",
      staticStyle: { height: "100vh" },
    },
    [
      _c("h3", [_vm._v("Test Chat:")]),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass: "navbar-nav ml-auto",
          staticStyle: { "flex-direction": "row" },
          attrs: { "data-v-d5a71b3a": "" },
        },
        [
          _c(
            "li",
            {
              staticClass: "nav-item dropdown",
              attrs: { "data-v-d5a71b3a": "" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  staticStyle: {
                    height: "2.5rem",
                    position: "relative",
                    display: "block",
                    padding: "0.5rem 1rem",
                  },
                  attrs: {
                    "data-v-d5a71b3a": "",
                    "data-toggle": "dropdown",
                    href: "#",
                    "aria-expanded": "false",
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-comments",
                    attrs: { "data-v-d5a71b3a": "" },
                  }),
                  _vm._v(" "),
                  _vm.chatNotification.length != 0
                    ? _c(
                        "span",
                        {
                          staticClass: "badge badge-danger navbar-badge",
                          staticStyle: {
                            "font-size": ".6rem",
                            "font-weight": "300",
                            padding: "2px 4px",
                            position: "absolute",
                            right: "5px",
                            top: "9px",
                          },
                          attrs: { "data-v-d5a71b3a": "" },
                        },
                        [_vm._v(_vm._s(_vm.chatNotification.length))]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "dropdown-menu dropdown-menu-lg dropdown-menu-right",
                  attrs: { "data-v-d5a71b3a": "" },
                },
                [
                  _vm._l(_vm.chatNotification, function (item, idx) {
                    return _c(
                      "a",
                      {
                        key: idx,
                        staticClass: "dropdown-item",
                        attrs: { "data-v-d5a71b3a": "", href: "#" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "media",
                            attrs: { "data-v-d5a71b3a": "" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "media-body",
                                attrs: { "data-v-d5a71b3a": "" },
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "dropdown-item-title",
                                    attrs: { "data-v-d5a71b3a": "" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.name) +
                                        "\n                  "
                                    ),
                                    _vm._m(0, true),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "text-sm text-muted",
                                    attrs: { "data-v-d5a71b3a": "" },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-clock mr-1",
                                      attrs: { "data-v-d5a71b3a": "" },
                                    }),
                                    _vm._v(
                                      " \n                          " +
                                        _vm._s(item.created_at) +
                                        "\n                          "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "dropdown-divider",
                    attrs: { "data-v-d5a71b3a": "" },
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center h-100" }, [
        _c("div", { staticClass: "col-md-6 chat" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              {
                staticClass: "card-body msg_card_body",
                staticStyle: { "overflow-y": "auto" },
                attrs: { id: "private_room" },
              },
              _vm._l(_vm.chat.message.list, function (message) {
                return _c("MessageItem", {
                  key: message.id,
                  attrs: {
                    message: message,
                    parentDeleteMessage: _vm.deletMessage,
                  },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-footer" },
              [
                _c("b-form-file", {
                  attrs: { plain: "" },
                  model: {
                    value: _vm.file,
                    callback: function ($$v) {
                      _vm.file = $$v
                    },
                    expression: "file",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.inputMessage,
                        expression: "inputMessage",
                      },
                    ],
                    staticClass: "form-control type_msg",
                    attrs: { placeholder: "Type your message..." },
                    domProps: { value: _vm.inputMessage },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.saveMessage()
                      },
                      click: _vm.seenMessage,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.inputMessage = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "input-group-append",
                      on: {
                        click: function ($event) {
                          return _vm.saveMessage()
                        },
                      },
                    },
                    [_vm._m(1)]
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "float-right text-sm text-danger",
        attrs: { "data-v-d5a71b3a": "" },
      },
      [
        _c("i", {
          staticClass: "fas fa-star",
          attrs: { "data-v-d5a71b3a": "" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "input-group-text send_btn" }, [
      _c("i", { staticClass: "fas fa-location-arrow" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }