var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    _vm._g(
      {
        attrs: {
          id: _vm.idComponent,
          centered: "",
          "header-bg-variant": _vm.color,
          title: _vm.$t("panel.label_confirm_title"),
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c("p", { staticClass: "confirmText" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.confirmText) +
                      "\n            "
                  ),
                  _vm.customWarningText
                    ? _c(
                        "span",
                        { staticStyle: { color: "red", "font-size": "14px" } },
                        [_vm._v(_vm._s(_vm.customWarningText))]
                      )
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function (ref) {
              var ok = ref.ok
              var cancel = ref.cancel
              return [
                _c(
                  "div",
                  {
                    staticClass: "d-flex w-100 justify-content-end",
                    staticStyle: { gap: "5px" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: _vm.color },
                        on: {
                          click: function ($event) {
                            return ok()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.textButtonConfirm) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("b-button", { on: { click: cancel } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("panel.label_cancel_btn_modal")) +
                          "\n            "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }