export default {
    label_email: "Email",
    label_name: "Name",
    label_create_new_user: "Create new user",

    label_parent_agent: "Parent agent",
    label_status: "Status",
    label_name: "Name",
    agent_name: "Name",
    hierarchy: "Hierarchy",
    child_count: "Child count",
    action: "Action",
    label_create_new_agent: "Create new agent",
}
