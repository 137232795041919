export default {
    label_add_more: "＋予約追加",
    label_timezone_setting: "対応時間帯設定",
    label_add_reserve: "予約追加",
    label_agent: "代理店",
    label_job_seeker: "求職者",
    label_message_to_target_person: "対象者へのメッセージ",
    label_google_event_name: "Googleカレンダーのタイトル",
    label_zoom_url_account: "ZoomURLを作成する",
    label_meeting_url_account: "MeetURLを作成する",
    label_send_mail: "メール送信",
    label_send_chat: "チャット送信",
    label_today: "今日",
    label_month: "月",
    label_week: "週",
    label_day: "日",
    label_list: "リスト",
    label_holiday: "休日",
    label_from_date: "から",
    label_setting_working: "対応時間帯設定",
    label_sunday: '日曜日',
    label_monday: '月曜日',
    label_tuesday: '火曜日',
    label_wednesday: '水曜日',
    label_thursday: '木曜日',
    label_friday: '金曜日',
    label_saturday: '土曜日',
    label_time_start: '時間が始まる',
    label_time_end: '終了時間',
}
