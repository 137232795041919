import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'sales-management',
        component: () => import(/* webpackChunkName: "sales-management-layout"*/'../views/sales-management/Layout.vue'),
        name: 'SalesManagement',
        redirect: { name: 'SearchSalesManagement' },
        children: [
            {
                path: 'search',
                name: 'SearchSalesManagement',
                meta: {
                    title: i18n.t("menu.sales_management"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "search-sales-management-view" */'../views/sales-management/Search.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditSalesManagement',
                meta: {
                    title: i18n.t("menu.sales_management"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "edit-sales-management-view" */'../views/sales-management/Edit.vue')
            }
        ]
    }
];
