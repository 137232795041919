export default {
    label_add_more: "+ Reservation added",
    label_timezone_setting: "Correspondence time zone setting",
    label_add_reserve: "Add a new reservation",
    label_agent: "Agency",
    label_job_seeker: "Job seeker",
    label_message_to_target_person: "Message to the target person",
    label_google_event_name: "Google Calendar Title",
    label_zoom_url_account: "Create a Zoom URL",
    label_meeting_url_account: "Create a Meeting URL",
    label_send_mail: "Send mail",
    label_char_send: "Chat",
    label_today: "Today",
    label_month: "Month",
    label_week: "Week",
    label_day: "Day",
    label_list: "List",
    label_holiday: "Holiday",
    label_from_date: "From",
    label_setting_working: "対応時間帯設定"
}
