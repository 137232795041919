export default {
    label_status: "ステータス",
    label_fullname: "氏名",
    label_manager_name: "管理者名",
    label_email_address: "メールアドレス",
    label_password: "パスワード",
    label_telephone: "電話番号",
    label_administrator_type: "管理者種別",
    label_contact_address: "連絡先",
    label_last_login: "最終ログイン",
    label_status_inactive: "無効",
    label_status_active: "有効",
    label_add_new: "管理者追加",
    label_zoom_account: "Zoomアカウントのメールアドレス",
    label_calendar_id: "ミーティングメールアドレス",
    label_role_system_admin: "システム管理者",
    label_google_link: "Googleカレンダーを連携します。\n よろしいですか？",
    label_google_unlink: "Googleカレンダーを連携解除します。\n 解除するとカレンダー管理から \n 連携されたイベントが削除されます。 \n よろしいですか？",
    label_google_retry_link: "Googleカレンダーを再連携します。\n 再連携するアカウントは連携時と \n 同じアカウントを指定してください。\n よろしいですか？",
    label_google_unlink_button: "解除する",
    label_google_retry_link_button: "再連携する",
}
