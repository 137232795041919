/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import App from './App.vue';
import Vue from 'vue';
import router from './routes';
import store from './stores';
import i18n from './plugins/i18n';
import Swal from 'sweetalert2';
import { Form, HasError, AlertError } from 'vform';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { FadeTransition } from 'vue2-transitions';
import VCalendar from 'v-calendar';
import VueTheMask from 'vue-the-mask';
import CircleLoading from './components/loading/circleLoading.vue';
import GrowLoading from './components/loading/growLoading';
import './filters'
import Vue2Editor from 'vue2-editor';
import CoolLightBox from 'vue-cool-lightbox';

// call global component
import "./components/cores"
import "./directives";

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

window.Form = Form;

window.Vue = Vue;
window.router = router;
//
window.setSearchingRoute = function (routeName) {
    let StorageManageHelper = require('./helpers/storageManage').default;
    let { pathname, search } = window.location;
    let routingPath = `${pathname}${search}`;
    let storage = {};
    storage[routeName] = routingPath;
    StorageManageHelper.setStorage(storage);
}

window.goBackSearchingRoute = function (routeName) {
    let StorageManageHelper = require('./helpers/storageManage').default;
    let oldPath = StorageManageHelper.getStorage(routeName);
    if (!oldPath) {
        return { name: routeName};
    }
    return oldPath;
}

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

// ----- GLOBAL COMPONENT --------
Vue.component('fade-transition', FadeTransition);
Vue.component('circle-loading', CircleLoading);
Vue.component('grow-loading', GrowLoading);

// Filter Section

Vue.filter('formatDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)


// plugin
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vue2Editor);
Vue.use(VueTheMask);
Vue.use(CoolLightBox);

Vue.prototype.$axios = axios
Vue.prototype.$Echo = Echo
Vue.prototype.scrollTopWindow = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

// create process

Vue.prototype.$createProcess = async function ({createFunc, data, scrollTop = true, message = '', directTo = ''}) {
    if (typeof createFunc != 'function') throw new Error(`createFunc param should be a function`);
    try {

        await createFunc(data);
        // redirect to screen
        if (directTo)
            this.$router.push(window.goBackSearchingRoute(directTo));
        // call store to announce success
        store.dispatch('createSuccess', {message});
        if (scrollTop)
            this.scrollTopWindow();
    } catch (err) {
        this.scrollTopWindow();
        if (err.validateFail) {
            return new Promise((resolve, reject) => reject(err))
        }
        // call store to announce fail
        store.dispatch('failRetreive');
        return;
    }
}
// update process

Vue.prototype.$updateProcess = async function ({updateFunc, data, scrollTop = true, message = '', returnResponse = false}) {
    if (typeof updateFunc != 'function') throw new Error(`updateFunc param should be a function`);
    const {id, body} = data;
    try {
        let response = await updateFunc(id, body);
        // call store to announce success
        store.dispatch('updateSuccess', {message});
        if (scrollTop)
            this.scrollTopWindow();
        if (returnResponse) {
            return response
        }
    } catch (err) {
        this.scrollTopWindow();
        if (err.validateFail) {
            return new Promise((resolve, reject) => reject(err))
        }
        // call store to announce fail
        store.dispatch('failRetreive');
        return;
    }
}

// delete process
Vue.prototype.$deleteProcess = async function ({deleteFunc, id, scrollTop = true, message = '', directTo = ''}) {
    if (typeof deleteFunc != 'function') throw new Error(`deleteFunc param should be a function`);
    try {
        await deleteFunc(id);
        if (directTo)
            this.$router.push(window.goBackSearchingRoute(directTo)); // go to Search Advisor
        // call store to announce success
        store.dispatch('deleteSuccess', {message});
        if (scrollTop)
            this.scrollTopWindow();
    } catch (err) {
        this.scrollTopWindow();
        // call store to announce fail
        store.dispatch('failRetreive');
        return;
    }
}

Vue.use(VCalendar);
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app-main');
