import ApiGateway from "../plugins/gateways";
import HttpClient  from "../plugins/httpClient";

var httpClient = new HttpClient(ApiGateway.localhost)
const prefix = '/auth';

export default {
  login: (postData) => httpClient.post(`${prefix}/login`, {}, postData),
  refreshToken: () => httpClient.post(`${prefix}/refresh-token`),
  register: (postData) => httpClient.post(`${prefix}/register`, {}, postData),
  logout: () => httpClient.post(`${prefix}/logout`),
  passwordReissue: (postData) => httpClient.post(`${prefix}/reissue`, {}, postData)
}
