<template>
    <!-- for advisor person_flag = 0 -->
    <div
        class="msg-item d-flex justify-content-end mb-4 private"
        v-if="message.isTemp"
    >
        <div
            class="msg_container_send blur"
            data-toggle="tooltip"
            data-placement="top"
        >
            <div v-html="messageContent"></div>
        </div>
    </div>

    <!-- for advisor person_flag = 0 -->
    <div
        class="msg-item d-flex justify-content-end mb-4 private"
        v-else-if="message.person_flag == 0"
    >
    <button @click="deleteMessage(message.id)">delete</button>
        <div
            class="msg_container_send"
            data-toggle="tooltip"
            data-placement="top"
            :title="message.created_at | toLocalTime"
        >
            <div v-html="messageContent"></div>
        </div>
        <div
            class="img_cont_msg"
            data-toggle="tooltip"
            data-placement="top"
            :title="`${message.advisor.name} (${message.advisor.user.email})`"
        >
            <img
                :src="message.advisor.avatar"
                class="rounded-circle user_img_msg"
            />
        </div>
    </div>

    <!-- for job_seeker person_flag = 1| != 0 -->
    <div class="msg-item d-flex justify-content-start mb-4 private" v-else>
        <div
            class="
                img_cont_msg
                bg-white
                rounded-circle
                d-flex
                justify-content-center
                align-items-center
            "
            data-toggle="tooltip"
            data-placement="top"
            :title="`${message.job_seeker.name} (${message.job_seeker.user.email})`"
        >
            <span
                class="
                    rounded-circle
                    d-flex
                    justify-content-center
                    align-items-center
                "
                >{{ message.job_seeker.name[0].toUpperCase() }}</span
            >
        </div>
        <div
            class="msg_container"
            data-toggle="tooltip"
            data-placement="top"
            :title="message.created_at | toLocalTime"
        >
            <div v-html="messageContent"></div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import sanitizeHtml from "sanitize-html";

export default {
    components: {},
    props: {
        parentDeleteMessage: Function,
        message: {
            required: true,
        },
    },
    mounted() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip();
        });
    },
    methods: {
        deleteMessage(id){
            this.parentDeleteMessage(id)
        }
    },
    computed: {
        messageContent() {
            // console.log(this.message)
            if ((["image/jpeg","image/png"]).includes(this.message.attachment_type) ) {
                return `<img class="msg_img" src="/storage${this.message.attachment_link}" alt="${this.message.message}" />`;
            }else if(this.message.attachment_type != null){
                return `<a href="/storage${this.message.attachment_link}" download>${this.message.message}</a>`;
			}
            const content = sanitizeHtml(this.message.message);
            return content;
        },
    },
};
</script>

<style lang="scss">
.bot-notification {
    max-width: 100% !important;
    width: 100%;
    border-radius: 4px;
    background-color: #043244;
}
.msg-item {
    &.private {
        .msg-actions {
            i {
                &:hover {
                    color: #054760 !important;
                }
            }
        }
    }
    &:hover {
        .msg-actions {
            opacity: 1;
        }
    }
    .msg-actions {
        opacity: 0;
        transition: opacity 0.2s;
        i {
            color: lightgray;
            cursor: pointer;
            transition: color 0.2s;
            &:hover {
                color: white;
            }
        }
    }
}

.highlightText {
    color: #f1765e;
    font-weight: 600;
    cursor: pointer;
}

.bg-gray {
    background-color: #f1f0f0;
    color: #444950;
}

.img_cont_msg {
    width: 40px;
    height: 40px;
}
.user_img_msg {
    height: 40px;
    width: 40px;
    border: 2px solid #f5f6fa;
}
.msg_container_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #E2F6FF;
    padding: 10px;
    position: relative;
    color: black;
    word-break: break-word;
    max-width: 70%;
}
.msg_container_send.blur{
	opacity: 0.5;
}
.msg_container {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #E2FFF1;
    padding: 10px;
    position: relative;
    color: black;
    word-break: break-word;
    max-width: 70%;
}
.msg_img {
    width: 100%;
    border-radius: 10px;
}
</style>
