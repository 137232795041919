export default {
    label_search: "Search",
    label_reset: "Clear",
    label_no_data: "List is empty! No results...",
    label_please_choose: "Please select an option",
    label_no_data: "No information to display",
    label_register: "Create",
    label_go_back: "Back",
    label_create: "Create new",
    label_confirm_text_save: "I will register. Is it OK?",
    label_confirm_text_remove: "Delete. Is it ok?",
    label_confirm_title: "Confirm",
    label_edit: "Edit",
    label_remove: "Remove",
    label_delete_btn_modal: "Delete",
    label_cancel_btn_modal: "Cancel",
    label_please_enter: "Please enter",
    label_all: "All",
    label_action: "Action",
    label_index: "Order",
    label_list: "List",
    label_login: "Login",
    label_create_success: "Create successfully",
    label_update_success: "Update successfully",
    label_delete_success: "Delete successfully",
    label_link_success: "Link successfully",
    label_unlink_success: "Unlink successfully",
    label_update_fail: "Cannot be updated. Please check the contents.",
    label_delete_fail: "It cannot be deleted. Please check the contents.",
    label_create_fail: "It cannot be created. Please check the contents.",
    label_link_fail: "It cannot be linked. Please try again.",
    label_unlink_fail: "It cannot be unlinked. Please try again.",
    label_retry_link_fail: "This is a different Google account than the one you are currently linked to. If you wish to change your account, please unlink it once.",
    label_google_api_fail_400: "Please re-connect your Google account.",
    label_google_api_fail_404: "Please re-register and link your calendar ID and google account.",
    label_google_api_fail_other: "Failed to retrieve the schedule from Google Calendar.",
    label_google_link: "Google calender link",
    label_google_unlink: "Google calender unlink",
    label_google_retry_link: "Google calender reconnection",
    label_fail: "Fail!",
    label_failed_validation: "Validation is fail! Please check all inputs",
    label_place_holder: "Please fill text"
}
