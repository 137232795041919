<template>
    <div>
        <fade-transition :duration="200" mode="out-in">
            <router-view></router-view>
        </fade-transition>
    </div>
</template>
<script>
export default {
    name: 'App',
}
</script>
