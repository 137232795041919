var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-wrapper" },
    [
      _c("b-form-file", {
        attrs: { accept: _vm.accept, plain: "" },
        on: { change: _vm.handleUploadFile },
        model: {
          value: _vm.file,
          callback: function ($$v) {
            _vm.file = $$v
          },
          expression: "file",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error",
            },
          ],
          staticClass: "text-danger",
          style: _vm.messageStyle,
        },
        [_vm._v(_vm._s(_vm.errorMessage))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "review-file mt-2",
          on: {
            click: function ($event) {
              _vm.reviewSource = true
            },
          },
        },
        [
          _vm.isCanReviewImage && _vm.mainViewImage.src
            ? [
                _c(
                  "b-img-lazy",
                  _vm._b({}, "b-img-lazy", _vm.mainViewImage, false)
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isCanReviewVideo && _vm.mainViewVideo.src
            ? [
                _c("b-embed", {
                  attrs: {
                    type: "video",
                    src: _vm.mainViewVideo.src,
                    allowfullscreen: "",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.mainViewVideo.src && _vm.isCanReviewVideo && _vm.btnRemoveVideo
            ? _c(
                "b-button",
                {
                  staticClass: "btn-sm btn-remove-video",
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeVideo.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "i",
                    {
                      staticClass: "fas fa-times-circle",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("advisor.remove_video"))),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            centered: "",
            "hide-header": "",
            "ok-only": "",
            "ok-variant": "primary",
            "ok-title": _vm.textButtonMdal,
          },
          model: {
            value: _vm.reviewSource,
            callback: function ($$v) {
              _vm.reviewSource = $$v
            },
            expression: "reviewSource",
          },
        },
        [
          _vm.isCanReviewImage
            ? [
                _c("b-img", {
                  attrs: {
                    src: _vm.mainViewImage.src,
                    "fluid-grow": "",
                    alt: "Review popup image",
                  },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isCanReviewVideo
            ? [
                _c(
                  "video",
                  {
                    staticClass: "modal-video-review",
                    attrs: { controls: "" },
                  },
                  [
                    _c("source", {
                      attrs: {
                        type: _vm.mainViewVideo.type,
                        src: _vm.mainViewVideo.src,
                      },
                    }),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }