import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';
import Store from '../stores'


export default [
    {
        path: 'advisor',
        component: () => import(/* webpackChunkName: "advisor-layout"*/'../views/advisors/Layout.vue'),
        name: 'AdvisorManagement',
        redirect: { name: 'SearchAdvisor' },
        children: [
            {
                path: 'search',
                name: 'SearchAdvisor',
                meta: {
                    title: i18n.t("menu.advisor_default"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "search-advisor-view" */'../views/advisors/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateAdvisor',
                meta: {
                    title: i18n.t("menu.advisor_default"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin ],
                },
                component: () => import(/* webpackChunkName: "create-advisor-view" */'../views/advisors/Create.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditAdvisor',
                meta: {
                    title: i18n.t("menu.advisor_default"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "edit-advisor-view" */'../views/advisors/Edit.vue')
            }
        ]
    }
]
