import ChatService from '../services/chat.service';
import { formatMessage } from '../helpers/common';
import { PersonFlag, UserRoles } from '../plugins/constants';
import moment from 'moment';

const state = {

    formData: {
        advisor_id: null,
        job_seeker_id: null,
        message: '',
        person_flag: 0, // default: present for advisor role
        // file: null
    },
    messages: [],
    config: {
        loadingListMessage: false,
        loadMore: false
    },
    openChatBox: false,
    idViewingImgVideoFile: null
};
const getters = {
    getListMessage: (state) => state.messages,
    getAdvisorId: (state) => state.formData.advisor_id,
    getJobSeekerId: (state) => state.formData.job_seeker_id,
    getLoadingMessage: (state) => state.config.loadingListMessage,
    getCurrentRoom: (state) => {
        return  state.formData.advisor_id + '_' + state.formData.job_seeker_id;
    },
    filterImageVideoFiles: (state) => {
        let filterImageVideo = state.messages
                .filter(item => item.type && (item.type.includes('image') || item.type.includes('video')))
                .map(item => ({...item, src: item.attachmentLink, title:item.message, description:''}));
            if (filterImageVideo.length) {
                return filterImageVideo;
            }
            return []
    }
};

const mutations = {
    SET_ADVISOR_ID: (state, id) => {
        state.formData.advisor_id = id;
    },
    SET_JOB_SEEKER_ID: (state, id) => {
        state.formData.job_seeker_id = id;
    },
    SET_PERSON_FLAG: (state, flag) => {
        state.formData.person_flag = flag;
    },
    SET_OPEN_CHAT: (state, flag) => {
        state.openChatBox = flag;
    },
    REFRESH: (state, payload) => {
        state.formData = {
            advisor_id: null,
            job_seeker_id: null,
            message: '',
            person_flag: 0, // default: present for advisor role
        },
        state.messages = [];
    },
    SET_FILE: (state, file) => {
        if (file) {
            state.formData.file = file;
        } else {
            if (typeof state.formData.file != 'undefined') {
                delete state.formData.file
            }
        }
    },
    SET_MESSAGE: (state, text) => {
        state.formData.message = text;
    },

    PUSH_MESSAGE_ITEM: (state, message) => {
        state.messages.push(message);
    },

    PREPEND_LIST_MESSAGE: (state, list) => {
        let oldMessages = state.messages;
        state.messages = _.concat(list, oldMessages);
    },
    DELETE_MESSAGE_ITEM: (state, id) => {
        state.messages = state.messages.filter(item => item.id !== id);
    },
    SET_LOADING_GET_MESSAGE: (state, flag) => {
        state.config.loadingListMessage = flag;
    },
    SET_VIEWING_IMAGE_VIDEO: (state, flag) => {
        state.idViewingImgVideoFile = flag;
    }
};

const actions = {
    async sendMessage({state, commit, dispatch}) {
        if (_.isEmpty(state.formData.message) && !state.formData.file) return;

        let formData = new FormData();
        formData.append('file', state.formData.file);
        formData.append('advisor_id', state.formData.advisor_id);
        formData.append('job_seeker_id', state.formData.job_seeker_id);
        formData.append('message', state.formData.message);
        formData.append('person_flag', state.formData.person_flag);

        try {
            await ChatService.sendMessage(formData);
            // refresh state
            state.formData = {
                ...state.formData,
                message: '',
            }
            // commit
            commit('SET_FILE', null);
            // dispatch
            //dispatch('formatMessageAndAdd', response);
        } catch (err) {
            console.log(err)
        }
    },
    formatMessageAndAdd({commit, state}, data) {
        let format = formatMessage(data);
        if (!state.messages.some(item => item.id == format.id)) {
            commit('PUSH_MESSAGE_ITEM', format);
        }
    },
    // get list message
    async getListMessage({state, commit }) {
        if (state.config.loadingListMessage == false) {
            commit('SET_LOADING_GET_MESSAGE', true);
            let response = await ChatService.getListMessage({
                advisor_id: state.formData.advisor_id,
                job_seeker_id: state.formData.job_seeker_id,
                from: state.messages.length
            });
            let listMessage = _.sortBy(response, ['id']);

            // set false
            commit('SET_LOADING_GET_MESSAGE', false);
            if (!_.isEmpty(listMessage)) {
                listMessage = listMessage.map(item => {
                    return formatMessage(item);
                });
                //
                commit('PREPEND_LIST_MESSAGE', listMessage);
            }
        }
    },
    // seen message
    async seenLastMessage({state, rootState}) {
        const role = JSON.parse(rootState.Auth.userProfile).role_id;

        if (state.formData.person_flag == PersonFlag.advisor) {
            let lastMessageFromJob = _.last(_.cloneDeep(state.messages).filter(item => item.personFlag == PersonFlag.jobSeeker));
            // if having record lastMassageFromJob and seenAt is false => update seen_at
            if (role === UserRoles.advisor && lastMessageFromJob && lastMessageFromJob.seenAt == null) {
                try {
                    let current = moment().format('YYYY-MM-DD HH:mm:ss')
                    await ChatService.messageHasSeen({
                        job_seeker_id: state.formData.job_seeker_id,
                        advisor_id: state.formData.advisor_id,
                        person_flag: PersonFlag.advisor,
                        seen_at: current
                    })

                    // update to list message with seenAt
                    state.messages = state.messages.map(item => ({...item, seenAt: item.id == lastMessageFromJob.id ? current : null}))
                } catch (err) {
                    console.log(err)
                }
            }
        }
    },
    // initialData
    initialData({state ,commit}, payload) {
        const { advisor_id, job_seeker_id } = payload;
        state.messages = [];
        state.formData.advisor_id = advisor_id;
        state.formData.job_seeker_id = job_seeker_id;
        commit('SET_OPEN_CHAT', true); // set open chat
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
