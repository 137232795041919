import Auth from './auth';
import Panel from './panel';
import User from './user';
import Agent from './agent';
import AdminManage from './admin-manage';
import Account from './account';
import Advisor from './advisor';
import Calendar from './calendar';

export default {
    "auth": Auth,
    "panel": Panel,
    "user": User,
    "agent": Agent,
    "admin": AdminManage,
    "account": Account,
    "advisor": Advisor,
    "calendar": Calendar
}
