import { UserRoles } from '../plugins/constants';
import i18n from "../plugins/i18n";

export default [
    {
        path: 'notification',
        component: () => import(/* webpackChunkName: "notification-layout"*/'../views/notification/Layout.vue'),
        name: 'NotificationManagement',
        redirect: { name: 'SearchNotification' },
        children: [
            {
                path: 'search',
                name: 'SearchNotification',
                meta: {
                    title: i18n.t("menu.notification"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "search-notification-view" */'../views/notification/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateNotification',
                meta: {
                    title: i18n.t("menu.notification"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "create-notification-view" */'../views/notification/Create.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditNotification',
                meta: {
                    title: i18n.t("menu.notification"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "edit-notification-view" */'../views/notification/Edit.vue')
            }
        ]
    }
];
