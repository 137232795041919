export default {
  defaultRequestTimeOut: 300000,
  statusCode: {
    noResponse: 444
  },
  storageName: {
    token: 'token_access',
    userInfo: 'user_info',
    expiredAt: 'expired_at'
  },

  fetchError: {
    errorBE: "Something went wrong! Maybe server's having a problem"
  },
  error: {

  }
}

// define constant UserRoles
export const UserRoles = {
    systemAdmin: 1,
    admin: 2,
    advisor: 3,
    agent: 4,
    jobSeeker: 5
}


// define constant of notify_type
export const NotifyType = {
    none: 0,
    sendMail: 1,
    sendChat: 2,
    sendAll: 3
}

// define constnt of chat
export const PersonFlag = {
    advisor: 0,
    jobSeeker: 1
}

export const configModeDate = {
    type: 'string',
    mask: 'YYYY-MM-DD',
}


// channels
export const Channels = {
    chatNotification: 'ChatNotification',
    receiveMessage: 'MessagePosted',
    deleteMessage: 'MessageDelete'
}


// list month
export const monthLabels = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"];

// list status contract agent
export const contractOptions = [
    {
        id: 0,
        text: 'なし'
    },
    {
        id: 1,
        text: '有'
    }
];


// status day_of_flag
export const statusDayOfFlag = {
    working: 0,
    offline: 1
}

// minute interval setting working time
export const minuteInterval = 30;

//max video size in GB
export const VIDEO_MAX_UPLOAD_SIZE = 1;