var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-alert",
    {
      attrs: { fade: "", variant: _vm.variantColor },
      model: {
        value: _vm.dismissCountDown,
        callback: function ($$v) {
          _vm.dismissCountDown = $$v
        },
        expression: "dismissCountDown",
      },
    },
    [_vm._v("\n        " + _vm._s(_vm.announceSystem.message) + "\n    ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }