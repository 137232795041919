export default {
    date_sales: "売上計上年月",
    company_name: "企業名",
    referral_agency: "紹介代理店",
    add_sales_information: "売上情報追加",
    decision_company: "決定企業",
    sales_amount: "売上金額",
    circle: "円",
    decision_date: "決定日",
    hire_date: "入社日",
    payment_day: "入金日",
    file: "ファイル",
    file_selection: "ファイル選択",
    job_seeker_name: "求職者名",
    date_decision: "決定月",
    date_attendance: "入社月",
    date_get_money: "入金月",
    date_of_recording_sales: "売上計上年月",
    advisor_name: "アドバイザー名",
    edit_sales_management: "売上情報編集",
    receive_amount: "売上金額",
    recruit_company: "決定企業"
}
