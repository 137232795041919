import { UserRoles } from './constants';
import Store from '../stores';

export default function redirectRouteByRole() {
    const data = {
        [UserRoles.systemAdmin]: { name: 'SearchAdmin' },
        [UserRoles.admin]: { name: 'EditAdmin', params: {id: Store.getters['Auth/getAccountId']}},
        [UserRoles.advisor]: { name: 'JobSeekerManagement'},
        [UserRoles.jobSeeker]: { name: 'JobSeekerManagement'}
    }
    return data[Store.getters['Auth/getRole']];
}

export function defaultRedirectAdminManagement() {
    const data = {
        [UserRoles.systemAdmin]: { name: 'SearchAdmin' },
        [UserRoles.admin]: { name: 'EditAdmin', params: {id: Store.getters['Auth/getAccountId']}},
    }
    return data[Store.getters['Auth/getRole']];
}
