import axios from 'axios'
import Constants from './constants.js'
import { buildHeaders, buildUrl, parseValidationMessage } from '../helpers/common.js';
import StatusCode from './statusCode.js';
import Store from '../stores';

const headerDefault = {
    'accept': 'application/json',
    'content-type': 'application/json',
}

export default class HttpClient{
    constructor(baseUrl) {

        this.axiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: Constants.defaultRequestTimeOut,
            headers: headerDefault
        });
        this.axiosDownloadInstance = axios.create({
            baseURL: baseUrl,
            timeout: Constants.defaultRequestTimeOut,
            headers: headerDefault,
            responseType: 'arraybuffer'
        });
    }

    getOptions(header = {}) {
        var options = {
            headers: buildHeaders(header)
        };
        return options;
    }

    // Handle error
    handleError(err) {
        if (err.response) {
            let statusCodeResponse = err.response.status; // get status from err
            let structureError = {};
            // 422 validation
            if (statusCodeResponse === StatusCode.HTTP_UNPROCESSABLE_ENTITY) {
                // dispatch validation
                Store.dispatch('setAlertSystem', {
                    show: true,
                    error: true,
                    message: err.response.data.message || 'Validation is fail'
                })
                //
                structureError.validateFail = true;
                structureError.data = parseValidationMessage(err.response.data.error);
                return structureError;
            }
            // 403 forbidden
            if (statusCodeResponse === StatusCode.HTTP_FORBIDDEN) {
                // dispatch error Unauthorized
                Store.dispatch('setAlertSystem', {
                    show: true,
                    error: true,
                    message: err.response.data.message || 'Validation is fail'
                })
                //
                structureError.data = err.response.data.message || 'Your action is not allowed!';
                return structureError;
            }

            structureError.data = err.response.data.message || err.response.data.data || 'Unknow error!';
            return structureError;
        }

        return err;
    }

    // Handle success
    handleSuccess(response) {
        // if (response.status === StatusCode.HTTP_OK) {
        //     let message = response.data.message || 'Successfull';
        //     Store.dispatch('setAlertSystem', {
        //         show: true,
        //         error: false,
        //         message: message
        //     })
        // }

        return response.data.data;
    }

    handleSuccessOnGoogleOauth(response) {
        return response.data;
    }

    get(url, query = {}, headers = {}, isGoogle = false) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.get(url, options)
                .then((res) => {
                    if(isGoogle){
                        resolve(this.handleSuccessOnGoogleOauth(res))
                    }
                    resolve(this.handleSuccess(res))
                })
                .catch(err => reject(this.handleError(err)));
        });
    }


    retrieveFileUrl(url, query = {}, headers = {}) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.get(url, options)
                .then((res) => resolve(this.handleSuccess(res)))
                .catch(err => reject(this.handleError(err)));
        });
    }

    post(url, query = {}, body = {}, headers = {}) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.post(url, body, options)
                .then((res) => resolve(this.handleSuccess(res)))
                .catch(err => reject(this.handleError(err)));
        });
    }

    put(url, query = {}, body = {}, headers = {}) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.put(url, body, options)
                .then((res) => resolve(this.handleSuccess(res)))
                .catch(err => reject(this.handleError(err)));
        });
    }

    delete(url, query = {}, headers = {}) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.delete(url, options)
                .then((res) => resolve(this.handleSuccess(res)))
                .catch(err => reject(this.handleError(err)));
        })
    }

    postFile(url, query = {}, file, body = {}, headers = {}, onUploadProgress = null) {
        let formData = new FormData();
        formData.append('file-upload', file);

        for (let key in body) {
            formData.append(key, body[key]);
        }

        let options = this.getOptions(headers);
        if (onUploadProgress) {
            options.onUploadProgress = onUploadProgress;
        }

        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosInstance.post(url, formData, options)
            .then((res) => resolve(this.handleSuccess(res)))
            .catch(err => reject(this.handleError(err)));
        });
    }

    downloadFile(url, query = {}, headers = {}) {
        let options = this.getOptions(headers);
        url = buildUrl(url, query);

        return new Promise((resolve, reject) => {
            this.axiosDownloadInstance.get(url, options)
                .then((res) => resolve(res.data))
                .catch(err => reject(this.handleError(err)));
        });
    }
}
