export default {
    payment_recording_date: "支払い計上年月",
    agent_name: "代理店",
    payment_status: "支払い状況",
    payment_month_from_company: "企業からの入金月",
    month_of_payment_to_the_agency: "代理店への支払月",
    remuneration_amount: "報酬金額（合計)",
    billing_date: "請求日",
    contract: "契約書",
    detail: "明細"
}
