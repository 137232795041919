export default {
    information: "お知らせ・メルマガ",
    type: "種別",
    send_target: "対象者",
    status: "状態",
    title: "タイトル",
    delivery_date: "配信日",
    start_time: "開始日",
    end_time: "終了日",
    notification: "お知らせ（アプリ）",
    email_magazine: "メルマガ（活動エリア絞り込み）",
    job_seeker: "求職者",
    agent: "代理店",
    job_seeeker_and_agent: "求職者・代理店",
    preparing: "準備中",
    published: "公開中",
    end_of_publication: "終了",
    title_create: "タイトル",
    body: "本文",
    information_added: "お知らせ追加",
    information_edit: "お知らせ編集",
    push_notifications_delivery: "配信時、Push通知を送信する",
    please_specify_valid_file: "有効のファイルを指定してください。",
    please_specify_file_5mb_less: "5MB以下のファイルを指定してください",
    end_publication_type0: "公開終了",
    end_publication_type1: "送信済"
}

