<template>
    <div class="upload-wrapper">
        <b-form-file
            v-model="file"
            :accept="accept"
            @change="handleUploadFile"
            plain></b-form-file>
        <div v-show="error" class="text-danger" v-bind:style="messageStyle">{{ errorMessage }}</div>
        <!-- review image -->
        <div class="review-file mt-2" @click="reviewSource = true">
            <template v-if="isCanReviewImage && mainViewImage.src">
                <b-img-lazy
                    v-bind="mainViewImage"
                />
            </template>

            <template v-if="isCanReviewVideo && mainViewVideo.src">
                <b-embed
                    type="video"
                    :src="mainViewVideo.src"
                    allowfullscreen
                ></b-embed>
            </template>
        </div>
        <div>
            <b-button class="btn-sm btn-remove-video" v-if="mainViewVideo.src && isCanReviewVideo && btnRemoveVideo"  variant="danger" @click.prevent="removeVideo">
                <i class="fas fa-times-circle" aria-hidden="true"><span>{{ $t('advisor.remove_video')}}</span></i>
            </b-button>
        </div>

        <b-modal
            v-model="reviewSource"
            size="lg"
            centered
            hide-header
            ok-only ok-variant="primary" :ok-title="textButtonMdal"
            >
                <template v-if="isCanReviewImage">
                    <b-img :src="mainViewImage.src" fluid-grow alt="Review popup image"></b-img>
                </template>
                <template v-if="isCanReviewVideo">
                    <video controls class="modal-video-review">
                        <source :type="mainViewVideo.type" :src="mainViewVideo.src"/>
                    </video>
                </template>
            </b-modal>
    </div>
</template>

<script>
import _ from 'lodash';
export default {
    name: 'UploadFileCore',
    props: {
        error: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: '',
        },
        accept: {
            type: String,
            default: 'image/*'
        },
        src: {
            type: String,
            default: '',
        },
        messageStyle: {
            type: Object,
            default: function () { return {} }
        },
        textButtonMdal: {
            type: String,
            default: 'OK'
        },
        btnRemoveVideo: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isCanReviewImage() {
            return this.accept.includes('image');
        },
        isCanReviewVideo() {
            return this.accept.includes('video');
        }
    },
    watch: {
        file() {
            this.$emit('input', this.file);
        },
        src() {
            this.initializeSourceFile();
        }
    },
    data() {
        return {
            file: null,
            mainViewImage: {
                blank: true,
                blankColor: '#bbb',
                height: 120,
                width: 125,
                // rounded: "circle",
                src: null,
                fluid: true,
            },
            reviewSource: false,
            mainViewVideo: {
                src: '',
                type: 'video/mp4',
            }
        }
    },
    created() {
        this.initializeSourceFile()
    },
    methods: {
        handleUploadFile(event) {
            if (event.target.files.length) {
                var src = URL.createObjectURL(event.target.files[0]);
                let fileType = event.target.files[0].type;
                if (this.isCanReviewImage) {
                    this.mainViewImage.src = src
                    let imageReview = {
                        thumb: src,
                        src: src,
                        capion: 'Review image',
                    }
                    this.media = [imageReview];
                }
                if (this.isCanReviewVideo) {
                    this.mainViewVideo = {
                        src: src,
                        type: fileType
                    }
                }
            }
        },
        initializeSourceFile() {
            if (this.src && this.isCanReviewImage) {
                this.mainViewImage.src = _.cloneDeep(this.src)
            }
            if (this.isCanReviewVideo) {
                this.mainViewVideo.src = _.cloneDeep(this.src);
            }
        },
        removeVideo() {
            this.mainViewVideo.src = '';
            this.file = null;
            this.$emit('remove-video', 1)
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-image-wrapper {
    display: block;
}
.embed-responsive {
    display: block;
    width: 125px;
}
.review-file {
    cursor: pointer;
    display: inline-block;
}

.modal-video-review {
    height: 50vh;
    width: 100%;
}

.btn-remove-video {
    display: flex;
    align-items: center;
    height: 2rem;

    span {
        font-weight: 500;
        padding-left: 5px;
    }
}
</style>
