import ChatService from '../services/chat.service'

const state = {
    chatNotification: [],
};

const getters = {
    getListChatNotification: (state) => state.chatNotification
};

const mutations = {
    UPDATE_CHAT_NOTIFICATION: (state, listChat) => {
        state.chatNotification = listChat
    },
    REMOVE_FROM_SEEKER_ID: (state, from_seeker_id_is_readed) => {
        state.chatNotification = state.chatNotification.filter(item => item.from_seeker_id != from_seeker_id_is_readed);
    }
};

const actions = {
    async getChatNotification({state}) {
        try {
            let response = await ChatService.getChatNotification();
            state.chatNotification = response.chatNotification;
        } catch (err) {
            console.log(err)
        }
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
