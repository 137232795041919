export default [
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "auth-layout"*/'../views/auth/AuthLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'Login',
                meta: {
                    title: 'Login page',
                    requireAuth: false,
                },
                component: () => import(/* webpackChunkName: "login-view" */'../views/auth/Login.vue')
            },
            {
                path: 'register',
                name: 'Register',
                meta: {
                    title: 'Register page',
                    requireAuth: false,
                },
                component: () => import(/* webpackChunkName: "login-view" */'../views/auth/Register.vue')
            },
        ]
    }
];
