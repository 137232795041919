export default {
    label_status: "Status",
    label_fullname: "Full name",
    label_manager_name: "Manager name",
    label_email_address: "Email address",
    label_password: "Password",
    label_telephone: "Telephone number*",
    label_administrator_type: "Administrator type",
    label_contact_address: "Contact address",
    label_last_login: "Last login",
    label_status_inactive: "Inactive",
    label_status_active: "Active",
    label_add_new: "Add a new manager",
    label_zoom_account: "Zoom account",
    label_calendar_id: "Calendar ID",
    label_role_system_admin: 'System admin',
    label_role_admin: 'Admin',
    label_google_link: "Link Google Calendar. \n Is it OK?",
    label_google_unlink: "Unlink Google Calendar. \n Delete linked events from calendar management. \n Is it OK?",
    label_google_retry_link: "Re-connect Google Calendar. \n Please specify the account to be re-linked \n as the same account as when the linkage was made.  Is it OK?",
    label_google_unlink_button: "Unlink",
    label_google_retry_link_button: "Re-connect",
}
