<template>
    <b-alert
        v-model="dismissCountDown"
        fade
        :variant="variantColor">
            {{ announceSystem.message }}
        </b-alert>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'AlertSystem',
    computed: {
        ...mapState(['announceSystem']),
        variantColor() {
            return this.announceSystem.error ? 'danger' : 'success'
        }
    },
    mounted() {
        this.dismissCountDown = 3; // 5 seconds
    },
    watch: {
        dismissCountDown() {
            if (!this.dismissCountDown) {
                this.$store.dispatch('setAlertSystem', {
                    show: false,
                    error: null,
                    messsage: '',
                })
            }
        }
    },
    data() {
        return {
            dismissCountDown: 0
        }
    },
}
</script>
