import AgentRoute from './agent.route';
import AdminManagementRoute from './admin-management.route';
import CalendarManagement from './calendar.route';
import AdvisorsRoute from './advisors.route';
import JobSeekerRoute from './job-seeker.route';
import NotificationRoute from './notification.route';
import ChatRoute from './chat.route';
import SalesManagementRoute from './sales-management.route';
import PaymentRoute from './payment.route';
import ProjectRoute from './project.route';

export default [
    {
        path: '/admin',
        component: () => import(/* webpackChunkName: "main-layout" */'../layout/Main.vue'),
        name: 'AdminDefault',
        meta: {
            requireAuth: true,
        },
        children: [
            ...AdminManagementRoute,
            ...AgentRoute,
            ...CalendarManagement,
            ...AdvisorsRoute,
            ...JobSeekerRoute,
            ...ChatRoute,
            ...NotificationRoute,
            ...SalesManagementRoute,
            ...PaymentRoute,
            ...ProjectRoute
        ]
    },
]
