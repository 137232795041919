export default {
    status: "ステータス",
    name: "求職者名",
    day_of_birth: "生年月日",
    day_of_birth_col: "年齢",
    desired_industry: "希望職種",
    desired_prefecture_id: "希望地域",
    advisor_id: "アドバイザー",
    agent_id: "代理店",
    chat: "チャット",
    status_interview_schedule_being_adjusted: "面談日程調整中",
    status_job_change_consultation_completed: "転職相談完了",
    status_documents_being_created: "書類作成中",
    status_introducing_jobs: "求人紹介中",
    status_during_a_company_interview: "企業面接中",
    status_waiting_for_pass_fail: "合否待ち",
    status_end_established: "終了（成立）",
    status_end_unsuccessful: "終了（不成立）",
    status_decline: "辞退",
    status_completed_joining_the_company: "入社完了",
    status_invalid: "無効",
    add_job_seeker_information: "求職者情報追加",
    tel: "電話番号",
    email: "メールアドレス",
    banned_industry: "禁止業種",
    employment_type_id: "希望雇用形態",
    current_profession: "今の職業",
    current_annual_income: "今の年収",
    change_job_count: "転職回数",
    desired_job_start_time: "入社希望時期",
    final_education: "最終学歴",
    qualitification: "資格",
    residence_prefecture_id: "在住都道府県",
    hobby: "趣味",
    job_change_urgency: "転職緊急度",
    interview_schedule: "初回面談日程",
    note: "注意事項",
    choice_interview_datetime: "選択",
    ten_thousand_yen: "万円",
    times: "回",
    all_items_required: "全ての項目が必須です",
    information_editing: "求職者情報編集",
    desired_date_of_selection: "  選択希望日",
    please_select_your_desired_time_zone: " 希望時間帯を選択してください",
    password_reissue: " ログイン情報送信",
    job_change_urgency_low: '低い',
    job_change_urgency_normal: '普通',
    job_change_urgency_high: '高い',
    job_seeker_list: '求職者リスト',
    time_meeting: '初回面談日程は必須です。',
    send_reset_mail_pass: "ログイン情報を求職者のメールアドレスに送信します。\n送信すると現在のパスワードがリセットされます。\nよろしいですか？",
    send_reset_mail_pass_warning_note: "※ログイン情報送信にはステータスが「無効」以外です。"
}
