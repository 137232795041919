import { render, staticRenderFns } from "./ModalConfirm.vue?vue&type=template&id=630d3379&scoped=true&"
import script from "./ModalConfirm.vue?vue&type=script&lang=js&"
export * from "./ModalConfirm.vue?vue&type=script&lang=js&"
import style0 from "./ModalConfirm.vue?vue&type=style&index=0&id=630d3379&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630d3379",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/asto/new-gate-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('630d3379')) {
      api.createRecord('630d3379', component.options)
    } else {
      api.reload('630d3379', component.options)
    }
    module.hot.accept("./ModalConfirm.vue?vue&type=template&id=630d3379&scoped=true&", function () {
      api.rerender('630d3379', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/cores/ModalConfirm.vue"
export default component.exports