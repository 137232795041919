export default {
    admin_management: "管理者管理",
    agent: "代理店管理",
    advisor: "ユーザー情報管理",
    calendar: "カレンダー管理",
    job_seeker: "求職者管理",
    notification: "お知らせ・メルマガ",
    logout: "ログアウト",
    user_information_management: "ユーザ情報管理",
    sales_management: "売上管理",
    payment_reward: "支払い報酬管理",
    project: "オススメ案件管理",
    advisor_default: "アドバイザー管理"
}
