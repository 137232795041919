import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'project',
        component: () => import(/* webpackChunkName: "project-layout"*/'../views/project/Layout.vue'),
        name: 'ProjectManagement',
        redirect: { name: 'SearchProject' },
        children: [
            {
                path: 'search',
                name: 'SearchProject',
                meta: {
                    title: i18n.t("menu.project"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "search-project-view" */'../views/project/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateProject',
                meta: {
                    title: i18n.t("menu.project"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin ,UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "create-project-view" */'../views/project/Create.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditProject',
                meta: {
                    title: i18n.t("menu.project"),
                    requireAuth: true,
                    permissions: [  UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "edit-project-view" */'../views/project/Edit.vue')
            },
        ]
    }
];
