export default {
    project_name: "案件名",
    company_name: "企業名",
    recruitment: "募集定員",
    date_of_expired: "有効期限",
    status: "ステータス",
    add_new: "オススメ案件追加",
    status_is_recruiting: "募集中",
    status_is_un_recruiting: "募集済",
    detail: "案件詳細",
    attach_file: "添付資料",
    title: "タイトル",
    file: "ファイル",
    add: "追加",
    area: "エリア",
    job_type: "職種",
    employment_type: "雇用形態",
    download: "ダウンロード",
    cancel_upddate_file: "戻る"
}
