<template>
    <select class="form-control select2" v-bind="$props">
       <slot></slot>
    </select>
</template>

<script>
export default {
    name: 'CoreSelect2',
    props: {
        options: Array,
        value: [String, Number],
        error: {
            type: Boolean,
            default: false
        },
        showPlaceHolder: {
            type: Boolean,
            default: true
        },
        hideSearch: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            localOptions: []
        }
    },
    computed: {
        labelDefault() {
            let data = [{
                id: '',
                text:  this.options ? this.$t("panel.label_please_choose") : this.$t("panel.label_no_data"),
            }]
            return data;
        },
        configSelect2() {
            let config = {
                theme: 'bootstrap4',
                data: this.localOptions,
                width: '100%'
            };
            if (!this.hideSearch) {
                config.minimumResultsForSearch = -1
            };
            return config;
        }
    },
    created() {
        window.addEventListener("resize", this.resizeWidth);
    },
    mounted: function() {
        this.initializeOption();
        var vm = this;

        $(this.$el)
            // init select2
            .select2(
                this.configSelect2
            )
            .val(this.value)
            .trigger("change")
            // emit event on change.
            .on("change", function() {
                vm.$emit("input", this.value);
            });
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeWidth);
    },
    watch: {
        value: function(value) {
            // update value
            $(this.$el)
                .val(value)
                .trigger("change");
        },
        options: function() {
            this.initializeOption()
            // update options
            $(this.$el)
                .empty()
                .select2(this.configSelect2);

            $(this.$el)
                .val(this.value)
                .trigger('change');

            if (!this.showPlaceHolder) {
                this.$emit('input', this.options[0].id)
            }
        },
        error: function(value) {
            if (value) {
                $(this.$el).addClass('is-invalid');
            } else {
                $(this.$el).removeClass('is-invalid');
            }
        }
    },
    destroyed: function() {
        $(this.$el)
            .off()
            .select2("destroy");
    },
    methods: {
        initializeOption() {
            if (this.showPlaceHolder) {
                this.localOptions = [...this.labelDefault, ...this.options];
            } else {
                this.localOptions = [...this.options];
            }
        },
        resizeWidth() {
            $(this.$el)
                .select2(this.configSelect2)
        }
    }
}
</script>
