var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-svg" }, [
    _c("svg", { attrs: { viewBox: "25 25 50 50" } }, [
      _c("circle", { attrs: { cx: "50", cy: "50", r: "20" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }