var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message.isTemp
    ? _c(
        "div",
        { staticClass: "msg-item d-flex justify-content-end mb-4 private" },
        [
          _c(
            "div",
            {
              staticClass: "msg_container_send blur",
              attrs: { "data-toggle": "tooltip", "data-placement": "top" },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.messageContent) } })]
          ),
        ]
      )
    : _vm.message.person_flag == 0
    ? _c(
        "div",
        { staticClass: "msg-item d-flex justify-content-end mb-4 private" },
        [
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.deleteMessage(_vm.message.id)
                },
              },
            },
            [_vm._v("delete")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "msg_container_send",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: _vm._f("toLocalTime")(_vm.message.created_at),
              },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.messageContent) } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "img_cont_msg",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title:
                  _vm.message.advisor.name +
                  " (" +
                  _vm.message.advisor.user.email +
                  ")",
              },
            },
            [
              _c("img", {
                staticClass: "rounded-circle user_img_msg",
                attrs: { src: _vm.message.advisor.avatar },
              }),
            ]
          ),
        ]
      )
    : _c(
        "div",
        { staticClass: "msg-item d-flex justify-content-start mb-4 private" },
        [
          _c(
            "div",
            {
              staticClass:
                "\n            img_cont_msg\n            bg-white\n            rounded-circle\n            d-flex\n            justify-content-center\n            align-items-center\n        ",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title:
                  _vm.message.job_seeker.name +
                  " (" +
                  _vm.message.job_seeker.user.email +
                  ")",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "\n                rounded-circle\n                d-flex\n                justify-content-center\n                align-items-center\n            ",
                },
                [_vm._v(_vm._s(_vm.message.job_seeker.name[0].toUpperCase()))]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "msg_container",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: _vm._f("toLocalTime")(_vm.message.created_at),
              },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.messageContent) } })]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }