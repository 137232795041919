import ApiGateway from "../plugins/gateways";
import HttpClient  from "../plugins/httpClient";

var httpClient = new HttpClient(ApiGateway.localhost)
const prefix = '/calendar';

export default {
  search: (query) => httpClient.get(`${prefix}`, query),
  create: (body) => httpClient.post(`${prefix}`, {}, body),
  delete: (userId) => httpClient.delete(`${prefix}/${userId}`), // /admin/1
  show: (userId) => httpClient.get(`${prefix}/${userId}`), // /admin/1/edit
  update: (userId, body) => httpClient.put(`${prefix}/${userId}`, {}, body),
  getCalendarDateOff: (query) => httpClient.get(`${prefix}/schedule`, query),
  updateCalendarDateOff: (body) => httpClient.put(`${prefix}/schedule`, {}, body),
  getCalendarDetail: (query) => httpClient.get(`${prefix}/interview-schedule`, query),
  createNewInterviewSchedule: (body) => httpClient.post(`${prefix}/interview-schedule/create`, {}, body),
  getListEventInterviewSchedule: (query) => httpClient.get(`${prefix}/interview-schedule/list`, query),
  getDetail: (eventId) => httpClient.get(`${prefix}/interview-schedule/${eventId}/edit`),
  updateInterview: (eventId, body) => httpClient.put(`${prefix}/interview-schedule/${eventId}/update`, {}, body),
  removeInterview: (eventId) => httpClient.delete(`${prefix}/interview-schedule/${eventId}`)
}
