<template>
    <div
        class="container position-relative chat-container"
        style="height: 100vh"
    >
	<h3>Test Chat:</h3>
    <ul data-v-d5a71b3a="" class="navbar-nav ml-auto" style="
    flex-direction: row;">
    <li data-v-d5a71b3a="" class="nav-item dropdown">
        <a data-v-d5a71b3a="" data-toggle="dropdown" href="#" aria-expanded="false" class="nav-link" style="height: 2.5rem;
    position: relative;
    display: block;
    padding: 0.5rem 1rem;">
    <i data-v-d5a71b3a="" class="far fa-comments">
        </i> 
        <span v-if="chatNotification.length != 0" data-v-d5a71b3a="" class="badge badge-danger navbar-badge" style="
    font-size: .6rem;
    font-weight: 300;
    padding: 2px 4px;
    position: absolute;
    right: 5px;
    top: 9px;">{{ chatNotification.length }}</span>
    </a> 
    <div data-v-d5a71b3a="" class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="">
        <a data-v-d5a71b3a="" href="#" class="dropdown-item" v-for="(item, idx) in chatNotification" :key="idx">
            <div data-v-d5a71b3a="" class="media">
                <!-- <img data-v-d5a71b3a="" src="dist/img/user1-128x128.jpg" alt="User Avatar" class="img-size-50 mr-3 img-circle">  -->
                <div data-v-d5a71b3a="" class="media-body">
                    <h3 data-v-d5a71b3a="" class="dropdown-item-title">
                  {{ item.name }}
                  <span data-v-d5a71b3a="" class="float-right text-sm text-danger">
                      <i data-v-d5a71b3a="" class="fas fa-star"></i>
                      </span>
                      </h3> 
                      <p data-v-d5a71b3a="" class="text-sm text-muted">
                          <i data-v-d5a71b3a="" class="far fa-clock mr-1"></i> 
                          {{ item.created_at }}
                          </p>
                          </div>
                          </div>
                          </a> 
                          
                          <div data-v-d5a71b3a="" class="dropdown-divider"></div>                           
                          </div></li></ul>
        <div class="row justify-content-center h-100">
            <div class="col-md-6 chat">
                <div class="card">
                    <div
                        class="card-body msg_card_body"
						id="private_room"
                        style="overflow-y: auto"
                    >
                        <MessageItem
                            v-for="message in chat.message.list"
                            :key="message.id"
                            :message="message"
                            :parentDeleteMessage="deletMessage"
                        />
                    </div>
					<div class="card-footer">
						<b-form-file
							v-model="file"
							plain></b-form-file>
						<div class="input-group">
							<input
								v-model="inputMessage"
								placeholder="Type your message..."
								class="form-control type_msg"
								@keyup.enter="saveMessage()"
                                @click="seenMessage"
							></input>
							<div class="input-group-append"
								@click="saveMessage()"
							>
								<span class="input-group-text send_btn">
									<i class="fas fa-location-arrow"></i>
								</span>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import sanitizeHtml from "sanitize-html";
import MessageItem from "./MessageItem";
import StorageManage from '../../helpers/storageManage';
import { ApplicationStorageToken } from '../../plugins/settings';
import { mapGetters } from 'vuex';

export default {
    components: {
        MessageItem,
    },
    data() {
        return {
            inputMessage: "",
            currentRoom: "",
            usersOnline: [],
            chat: {
                selectedReceiver: null,
                isPrivateChatExpand: false,
                seenAt: "",
                message: {
                    list: []
                },
			},
			file: null,
            selectedMessage: null,
            advisor_id: null,
            job_seeker_id: null,
            chatNotification: [],
            isSeen: false
        };
    },
    created() {
        this.advisor_id = this.$route.params.advisorId;
        this.job_seeker_id = this.$route.params.jobSeekerId;
        this.currentRoom = `${this.advisor_id}_${this.job_seeker_id}`;
        this.getMessages(this.advisor_id, this.job_seeker_id);
        this.updateNotification();
        this.$Echo
            .private(`room.${this.currentRoom}`)
            .listen("MessagePosted", (e) => {
                this.isSeen = false;
                this.chat.message.list.push(e.chat);
                this.scrollToBottom(
                    document.getElementById("private_room"),
                    true
                );
            }).listen("MessageDelete", (e) => {
                this.chat.message.list = this.chat.message.list.filter(i => i.id != e.chat.id);
            });
        // this.getUserProfile.id
        this.$Echo
            .private(`room.${this.getUserProfile.id}`)
            .listen("ChatNotification", (e) => {
                this.chatNotification = e.listUnSeenMessage;
                console.log(this.chatNotification)
            });
    },
    methods: {
        async getMessages(advisor_id, job_seeker_id, page = 1) {
            const chat = this.chat;
            try {
                chat.message.isLoading = true;
                const response = await this.$axios.get(
                    `/api/chat/messages?advisor_id=${advisor_id}&job_seeker_id=${job_seeker_id}&page=${page}`,
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
                );
                chat.message.list = [
                    ...response.data.data.data.reverse(),
                    ...chat.message.list,
                ];
                chat.message.currentPage = response.data.data.current_page;
                chat.message.perPage = response.data.data.per_page;
                chat.message.lastPage = response.data.data.last_page;
                chat.message.total = response.data.data.total;
                chat.message.newMessageArrived = response.data.data.data.length;

                this.scrollToBottom(
                    document.getElementById("private_room"),
                    false
                );
            } catch (error) {
                console.log(error);
            } finally {
                chat.message.isLoading = false;
            }
        },
         async updateNotification() {
            try {
                const response = await this.$axios.get(
                    `/api/chat/messages/getChatNotification`,
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
                );

                 this.chatNotification = response.data.data;
            } catch (error) {
                console.log(error);
            } finally {
            }
        },
        async saveMessage() {
            try {
				let message = sanitizeHtml(this.inputMessage).trim();
				this.inputMessage = "";
				if(this.file){
					await this.saveFile()
				}
				if (!message.length) {
					return;
				}
				//add tmp message
				let timeStamp = new Date().getTime();
				this.scrollToBottom(
                    document.getElementById(`private_room`),
                    true
                );
				this.chat.message.list.push(
					{
						message: message,
						isTemp : true,
						time: timeStamp
					}
				);
                const response = await this.$axios.post(
                    "/api/chat/messages",
                    {
                        advisor_id: this.advisor_id,
                        job_seeker_id: this.job_seeker_id,
                        message: message,
                        person_flag: this.getUserProfile.advisor ? 0 : 1,
                    },
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
				);
				//remove tmp message
				this.chat.message.list = this.chat.message.list.map(i =>  {
					if(i.isTemp && i.time == timeStamp) {
						return response.data.message;
					}
					return i;
				})
				// this.chat.message.list.push(response.data.message);
				// console.log(this.chat.message.list)
                this.scrollToBottom(
                    document.getElementById(`private_room`),
                    true
                );
            } catch (error) {
                console.log(error);
            }
		},
		async saveFile() {
            try {
				let formData = new FormData();
				formData.append('file', this.file);
				formData.append('advisor_id', this.advisor_id);
				formData.append('job_seeker_id', this.job_seeker_id);
				formData.append('person_flag', 0);
				this.file = null
                const response = await this.$axios.post(
                    "/api/chat/messages",
                    formData,
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
                );
                this.chat.message.list.push(response.data.message);
                this.scrollToBottom(
                    document.getElementById(`private_room`),
                    true
                );
            } catch (error) {
                console.log(error);
            }
        },
        async deletMessage(id){
            console.log(id)
            try {
                const response = await this.$axios.delete(
                    "/api/chat/messages/"+id,
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
				);
				//remove message
				this.chat.message.list = this.chat.message.list.filter(i => i.id != id);
            } catch (error) {
                console.log(error);
            }
        },
        async seenMessage () {
            console.log('fire')
            if(this.isSeen) return false;
            this.isSeen = true;
            try {
                const response = await this.$axios.post(
                    "/api/chat/messages/seen",
                    {
                        advisor_id: this.advisor_id,
                        job_seeker_id: this.job_seeker_id,
                        person_flag: this.getUserProfile.advisor ? 0 : 1,
                        seen_at: new Date()
                    },
                    {
                        headers: {
                            Authorization:
                                'Bearer ' + StorageManage.getStorage(ApplicationStorageToken)
                        },
                    }
				);
            } catch (error) {
                console.log(error);
            }            
        },
        scrollToBottom(element, animate = true) {
            if (!element) {
                return;
            }
            this.$nextTick(() => {
                if (animate) {
                    $(element).animate(
                        { scrollTop: element.scrollHeight },
                        { duration: "medium", easing: "swing" }
                    );
                } else {
                    $(element).scrollTop(element.scrollHeight);
                }
            });
        },
    },
    computed: {
        ...mapGetters('Auth', ['getUserProfile'])
    },
    watch: {},
    beforeDestroy() {
        this.$Echo.leave(`room.${this.currentRoom}`);
    },
};
</script>

<style scoped>
.card {
    height: 70vh;
    border-radius: 15px !important;
    background-color: rgb(60 104 44 / 60%) !important;
}
.chat {
    margin-top: auto;
    margin-bottom: auto;
}
.chat .card .card-footer {
    border-radius: 0 0 15px 15px !important;
    border-top: 0px !important;
}
</style>
