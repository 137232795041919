import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'payment-management',
        component: () => import(/* webpackChunkName: "payment-management-layout"*/'../views/payment/Layout.vue'),
        name: 'PaymentManagement',
        redirect: { name: 'SearchPaymentReward' },
        children: [
            {
                path: 'search',
                name: 'SearchPaymentReward',
                meta: {
                    title: i18n.t("menu.payment_reward"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin],
                },
                component: () => import(/* webpackChunkName: "payment-reward-view" */'../views/payment/Search.vue')
            },

        ]
    }
];
