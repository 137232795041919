export default {
    label_email: "Eメール",
    label_create_new_user: "新しいユーザーを作成する",
    label_please_choose: "選択してください",
    label_no_data: "表示する情報がありません",
    label_parent_agent: "親代理店",
    label_status: "ステータス",
    label_name: "代理店名",
    agent_name: "代理店名",
    hierarchy: "階層",
    child_count: "子代理店数",
    action: "操作",
    label_create_new_agent: "代理店情報追加",
    label_edit_agent: "代理店情報編集",
    agent_name_label: "お名前",
    agent_age_label: "年齢",
    interview_manager_label: "面談担当管理者",
    interview_schedule_label: "初回面談日程",
    agent_child_list_label: "子代理店リスト",
    screen_agent: "代理店",
    day_of_birth: "生年月日",
    label_contract: "契約書",
    label_havent_contract: "なし",
    label_have_contract: "有",
    pay_rate: "報酬率",
    corporate_name : '法人名',
    address : '所在地',
    activity_area_id : '活動エリア',
    bank_name : '金融機関名',
    bank_branch_name : '支店名',
    bank_account_type : '口座種別',
    bank_account_number : '口座番号',
    bank_account_holder : '口座名義',
    password_reissue: " ログイン情報送信",
    send_reset_mail_pass: "ログイン情報を代理店のメールアドレスに送信します。\n送信すると現在のパスワードがリセットされます。\nよろしいですか？",
    send_reset_mail_pass_warning_note: "※ログイン情報送信にはステータスが「代理店登録完了」のみです。"
}
