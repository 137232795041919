import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Store from '../stores';
import En from '../lang/en';
import Ja from '../lang/ja';

// use plugins
Vue.use(VueI18n);

const messages = {
    'en': En,
    'ja': Ja
};

export default new VueI18n({
    locale: Store.getters.getCurrentLang, // set default locale
    fallbackLocale: 'en', // set fallback if can not find locale to replace
    messages, // set locale messages
});
