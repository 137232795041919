import Constants from '../plugins/constants';
import StorageManage from './storageManage';
import { ApplicationStorageToken } from '../plugins/settings';
import Store from '../stores';
import { PersonFlag, UserRoles } from '../plugins/constants';

export function buildHeaders(headers) {
    let requestHeaders = getDefaultRequestHeaders();

    if (!headers) {
        return requestHeaders;
    }
    if (Array.isArray(headers)) {
        return requestHeaders;
    }
    for (let keyname in headers) {
        requestHeaders[keyname.toLowerCase()] = headers[keyname];
    }

    return requestHeaders;
}

function getDefaultRequestHeaders() {
  return {
    page: 1,
    timeout: Constants.defaultRequestTimeOut,
    "Content-Type": "application/json",
    "Authorization": "Bearer " + StorageManage.getStorage(ApplicationStorageToken),
    "lang": Store.getters.getCurrentLang
  }
}

export function buildUrl(url, query) {
  if (!query || query === {} || Array.isArray(query)) {
    return url;
  }
  for (const keyname in query) {
    if (typeof query[keyname] == 'object') {
      continue;
    }
    const param = `{${keyname}}`;
    while (url.includes(param)) {
      url = url.replace(param, query[keyname]);
    }
  }
  const queries = [];
  for (const keyname in query) {
    if (typeof query[keyname] !== 'object') {
      queries.push(`${keyname}=${query[keyname]}`);
      continue;
    }
    if (Array.isArray(query[keyname])) {
      queries.push(`${keyname}=${query[keyname].join(',')}`);
    } else {
      queries.push(`${keyname}=${JSON.stringify(query[keyname])}`);
    }
  }
  return `${url}?${queries.join('&')}`;
}


// parse Validation Message from laravel
export function parseValidationMessage(listMessage) {
    if (typeof listMessage != 'object') {
        throw new Error('Parameter type should be an array!');
        return;
    }
    let validation = {};
    for (let key in listMessage) {
        validation[key] = listMessage[key].join('\n');
    }
    return validation;
}


export function makeId(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() *
 charactersLength));
   }
   return result;
}


export function formatMessage(messageItem) {
    let myMessage = false,
        ownerMessage = '',
        avatar = '';
    let account_id_login = Store.getters['Auth/getAccountId'],
        role_id_login = Store.getters['Auth/getRole'];
    if (messageItem.person_flag == PersonFlag.advisor && role_id_login == UserRoles.advisor && messageItem.advisor_id == account_id_login) {
        myMessage = true;
    }

    if (messageItem.person_flag == PersonFlag.jobSeeker && role_id_login == UserRoles.jobSeeker && messageItem.job_seeker_id == account_id_login) {
        myMessage = true;
    }

    if ((Store.getters['Auth/isAdmin'] || Store.getters['Auth/isSystemAdmin']) && messageItem.person_flag == PersonFlag.advisor) {
        myMessage = true;
    }

    if (messageItem.person_flag == PersonFlag.jobSeeker) {
        ownerMessage = messageItem.job_seeker.name;
    } else if (messageItem.person_flag == PersonFlag.advisor) {
        ownerMessage = messageItem.advisor.name
        avatar = messageItem?.advisor.avatar ? messageItem?.advisor.avatar : '';
    }

    return {
        id: messageItem.id,
        attachmentLink: messageItem.attachment_link ? `${messageItem.attachment_link}` : null,
        type: messageItem.attachment_type,
        myMessage: myMessage,
        message: messageItem.message,
        ownerMessage: ownerMessage,
        personFlag: messageItem.person_flag,
        seenAt: messageItem.seen_at,
        avatar: avatar
    }
}

export function formatMessageWithBreakLine(message) {
    return message.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

//check file size less or equal size in gb
export function checkFileLessOrEqualSize(obj, size, unit = 'gb') {
  if(obj instanceof File){
    return obj.size <= size * (unit=='gb'? 1024*1024*1024 : 1024);
  }
  return true;
}

export function awaitAll(promises) {
    if (!Array.isArray(promises)) throw new Error('The param should be an Array Type!');
    return new Promise((resolve, reject) => {
      Promise.all(promises).then(result => resolve(result)).catch(err =>{console.log(err); reject(err)});
    });
  }
