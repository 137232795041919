import { UserRoles } from '../plugins/constants';
import i18n from '../plugins/i18n';

export default [
    {
        path: 'job-seeker',
        component: () => import(/* webpackChunkName: "job-seeker-layout"*/'../views/job-seeker/Layout.vue'),
        name: 'JobSeekerManagement',
        redirect: { name: 'SearchJobSeeker' },
        children: [
            {
                path: 'search',
                name: 'SearchJobSeeker',
                meta: {
                    title: i18n.t("menu.job_seeker"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor],
                },
                component: () => import(/* webpackChunkName: "search-job-seeker-view" */'../views/job-seeker/Search.vue')
            },
            {
                path: 'create',
                name: 'CreateJobSeeker',
                meta: {
                    title: i18n.t("menu.job_seeker"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "create-job-seeker-view" */'../views/job-seeker/Create.vue')
            },
            {
                path: 'edit/:id',
                name: 'EditJobSeeker',
                meta: {
                    title: i18n.t("menu.job_seeker"),
                    requireAuth: true,
                    permissions: [ UserRoles.systemAdmin, UserRoles.admin, UserRoles.advisor ],
                },
                component: () => import(/* webpackChunkName: "edit-job-seeker-view" */'../views/job-seeker/Edit.vue')
            }
        ]
    }
];
