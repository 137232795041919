import VueRouter from "vue-router";
import Vue from 'vue';
import AdminRoute from './admin.route'
import AuthRoute from './auth.route';
import Store from '../stores'
import StorageManageHelper from '../helpers/storageManage';
import Room from '../views/chat/Room.vue'
import { UserRoles} from '../plugins/constants'
import RedirectRouteRole from '../plugins/redirectRouteRole';

Vue.use(VueRouter);

const routes = [
    ...AuthRoute,
    ...AdminRoute,
    {
        path: '*',
        component: () => import(/* webpackChunkName: "page-not-found" */ '../views/PageNotFound.vue')
    },
    {
        path: '/access-denied',
        name: 'AccessDenied',
        meta: {
            title: 'Access denied',
            requireAuth: true
        },
        component: () => import(/* webpackChunkName: "access-denied" */ '../views/AccessDenied.vue'),
    },
    // {
    //     path: '/chat/:advisorId/:jobSeekerId',
    //     name: 'room',
    //     meta: {
    //         title: 'Chat page',
    //         requireAuth: true
    //     },
    //     component: Room
    // },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

// before loading route
router.beforeEach((to, from, next) => {
  //document.title = to.meta.title ? to.meta.title : '';
  Store.dispatch('setAlertSystem', { show: false, error: false, message: ''})
  Store.commit('SET_ACCESS_PAGE', true);
  next();
});

//
router.beforeEach((to, from, next) => {
  const isAuth =  Store.getters['Auth/isAuth'];
  const role = Store.getters['Auth/getRole'];
  if (to.path === '/') {
    next({ name: 'Login' });
  }

  if (isAuth && to.name === 'Login') {
    next(RedirectRouteRole());
    return;
  }

  if (isAuth && /^\/admin\/?$/.test(to.path)) {
    next(RedirectRouteRole());
    return;
  }

  if (to.matched.some((res) => res.meta.requireAuth)) { // check route that using requireAuth
    if (isAuth) {

        // if (to.meta.permissions) { // check permission routing
        //     let permissions = to.meta.permissions;

        //     if (permissions.includes(role)) {
        //         next();
        //     } else {
        //         // if this routing is not allowed -> redirect to AccecssDenied page
        //         StorageManageHelper.setStorage({'goBackAccess': from.name});
        //         console.log(to);
        //         next({ name: 'AccessDenied' });
        //     }
        // } else {
        //     next();
        // }
        next();
    } else {
        StorageManageHelper.setStorage( {'redirectPath' : to.path} );
        next({ name: 'Login' });
    }
  } else {
    next();
  }
})

export default router;
