import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './auth.store';
import Calendar from './calendar.store';
import Chat from './chat.store';
import Notification from './notification.store';
import StorageManageHelper from '../helpers/storageManage';
import { ApplicationLang } from '../plugins/settings';
import i18n from '../plugins/i18n';

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    Auth,
    Calendar,
    Chat,
    Notification
  },
  state: {
    lang: StorageManageHelper.getStorage(ApplicationLang) || 'ja',
    announceSystem: {
        show: null,
        error: null,
        message: ''
    },
    isAccessToPage: true
  },
  getters: {
    getCurrentLang: (state) => state.lang
  },
  mutations: {
    SET_LANG: (state, lang) => {
        state.lang = lang
    },
    SET_ACCESS_PAGE: (state, flag) => {
        state.isAccessToPage = flag;
    }
  },
  actions: {
    changeLanguage({ commit }, lang) {
        commit('SET_LANG', lang)
        let storages = {};
        storages[ApplicationLang] = lang;
        StorageManageHelper.setStorage(storages);
    },
    storeSearchingQuery({ dispatch }) {
        console.log(dispatch);
        console.log(window.location.href);
    },
    setAlertSystem({state}, dispatch) {
        let { show, error, message } = dispatch;
        state.announceSystem = {
            show: show,
            error: error,
            message: message
        }
    },
    createSuccess({state}) {
        state.announceSystem = {
            show: true,
            error: false,
            message: i18n.t('panel.label_create_success')
        }
    },
    updateSuccess({state}, {message}) {
        state.announceSystem = {
            show: true,
            error: false,
            message: message ? message : i18n.t('panel.label_update_success')
        }
    },
    failRetreive({state}) {
        state.announceSystem = {
            show: true,
            error: true,
            message: i18n.t('panel.label_fail')
        }
    },
    deleteSuccess({state}, {message}) {
        state.announceSystem = {
            show: true,
            error: false,
            message: message ? message : i18n.t('panel.label_delete_success')
        }
    },
    GoogleApiFailed400({state}) {
        state.announceSystem = {
            show: true,
            error: true,
            message: i18n.t('panel.label_google_api_fail_400')
        }
    },
    GoogleApiFailed404({state}) {
        state.announceSystem = {
            show: true,
            error: true,
            message: i18n.t('panel.label_google_api_fail_404')
        }
      },
    GoogleApiFailedOther({state}) {
        state.announceSystem = {
            show: true,
            error: true,
            message: i18n.t('panel.label_google_api_fail_other')
        }
    },
  },
});

export default store;

