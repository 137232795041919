import Vue from 'vue';
//
import AlertSystem from './AlertSystem.vue';
import CoreModalConfirm from './ModalConfirm.vue';
import Select2 from './Select2.vue';
import UploadFileInput from './UploadFileInput.vue';
import TitleOfPage from './TitleOfPage.vue';
// register global component

Vue.component('alert-system', AlertSystem);
Vue.component('core-modal-confirm', CoreModalConfirm);
Vue.component('select-2', Select2);
Vue.component('core-upload-file-input', UploadFileInput);
Vue.component('core-title-of-page', TitleOfPage);
