import StorageManageHelper from '../helpers/storageManage';
import { ApplicationStorageToken, ApplicationStorageUserDataName, ApplicationExpiredAt } from '../plugins/settings';
import AuthService from '../services/auth.service';
import Router from '../routes';
import { UserRoles } from '../plugins/constants';
import redirectRouteByRole from '../plugins/redirectRouteRole'
import project from '../lang/ja/project';

const state = {
    userProfile: StorageManageHelper.getStorage(ApplicationStorageUserDataName),
    token: StorageManageHelper.getStorage(ApplicationStorageToken),
    expiredAt: StorageManageHelper.getStorage(ApplicationExpiredAt)
};
const getters = {
    getToken: (state) => state.token,
    isAuth: (state) => {
        if (state.expiredAt && Date.now() < parseInt(state.expiredAt) && state.token && state.userProfile) {
            return true;
        }
        return false;
    },
    getUserProfile: (state) => {
        if (state.userProfile) {
            return JSON.parse(state.userProfile);
        }
        return null;
    },
    getAccountId: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile.account_id;
        }
        return null;
    },
    getRole: (state) => {
        if (state.userProfile) {
            return parseInt(JSON.parse(state.userProfile).role_id);
        }
        return null;
    },
    isSystemAdmin: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile.role_id === UserRoles.systemAdmin;
        }
        return false;
    },
    isAdmin: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile.role_id === UserRoles.admin;
        }
        return false;
    },
    isAdvisor: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile.role_id === UserRoles.advisor;
        }
        return false;
    },
    isJobSeeker: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile.role_id === UserRoles.jobSeeker;
        }
        return false;
    },
    getRoleName: (state) => {
        if (state.userProfile) {
            let role_id = JSON.parse(state.userProfile).role_id;
            for (const prop in UserRoles) {
                if (UserRoles[prop] === role_id) {
                    return prop;
                }
            }
        }
        return null;
    },
    isAdminWithoutZoomAccount: (state) => {
        if (!state.userProfile) return true;
        else {
            let profile = JSON.parse(state.userProfile);
            if (!profile.admin) {
                return true;
            } else {
                return profile.admin.zoom_account ? false : true;
            }
        }
    },
    getAvatarAdvisor: (state) => {
        if (state.userProfile) {
            let profile = JSON.parse(state.userProfile);
            return profile?.advisor.avatar ? profile.advisor.avatar : '';
        }
        return null;
    }
};
const mutations = {
    SET_PROFILE(state, payload) {
        state.userProfile = payload; // will update late
    },
    SET_TOKEN(state, payload) {
        state.token = payload;
    },
    SET_EXPIRED_AT(state, payload) {
        state.expiredAt = payload;
    }
};
const actions = {
    async register({ commit }, payload) {
        try {
            let response = await AuthService.register(payload);
            let { expires_in, user_data, access_token } = response;
            commit('SET_TOKEN', access_token);
            commit('SET_PROFILE', JSON.stringify(user_data));
            let expiredAt = Date.now() + expires_in * 1000; // miliseconds
            commit('SET_EXPIRED_AT', expiredAt);
            let storages = {};
            storages[ApplicationStorageToken] = access_token;
            storages[ApplicationStorageUserDataName] = JSON.stringify(user_data);
            storages[ApplicationExpiredAt] = expiredAt;

            StorageManageHelper.setStorage(storages);
            Router.push({ name: 'Dashboard' })
        } catch (err) {
            throw err;
        }
    },
    async login({ commit }, payload) {
        try {
            let response = await AuthService.login(payload);
            let { expires_in, user_data, access_token } = response;
            commit('SET_TOKEN', access_token);
            commit('SET_PROFILE', JSON.stringify(user_data));
            let expiredAt = Date.now() + expires_in * 60 * 1000; // miliseconds
            commit('SET_EXPIRED_AT', expiredAt);
            let storages = {};
            storages[ApplicationStorageToken] = access_token;
            storages[ApplicationStorageUserDataName] = JSON.stringify(user_data);
            storages[ApplicationExpiredAt] = expiredAt;

            StorageManageHelper.setStorage(storages);
            if(StorageManageHelper.getStorage('redirectPath')){
                let redirect = StorageManageHelper.getStorage('redirectPath');
                StorageManageHelper.removeStorage('redirectPath');
                Router.push(redirect || '/');
            } else {
                Router.push(redirectRouteByRole());
            }
        } catch (err) {
            throw err;
        }
    },

    async logout({state, rootState}) {
        try {
            rootState.Chat.openChatBox = false
            await AuthService.logout();
            state.token = '';
            state.userProfile = null;
            state.expiredAt = null
            // remove Storage
            let listStorage = new Array(ApplicationStorageToken, ApplicationStorageUserDataName, ApplicationExpiredAt);
            StorageManageHelper.removeMultipleStorage(listStorage)

            // redirect to login
            Router.push({ name: 'Login' });
        } catch (err) {
            throw err;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
