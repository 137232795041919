export default {
    label_status: "ステータス",
    label_advisor: "アドバイザー",
    label_advisor_name: "アドバイザー名",
    label_decision_rate: "決定率",
    label_evaluation: "評価",
    label_active: "活動中",
    label_inactive: "活動終了",
    label_create_advisor: "アドバイザー追加",
    label_edit_advisor: "アドバイザー編集",
    label_advisor_email: "メールアドレス",
    label_advisor_password: "パスワード",
    label_advisor_age: "年齢",
    label_advisor_tel: "電話番号",
    label_advisor_education: "学歴",
    label_advisor_feature: "特徴・アピール点",
    label_advisor_zooom_account: "Zoomアカウント",
    label_advisor_calendar_id: "ミーティングメールアドレス",
    label_advisor_face_photo: "顔写真",
    label_advisor_pr_video: "PR動画",
    label_advisor_decision_rate: "決定率",
    label_advisor_user_evaluation: "利用者評価",
    label_advisor_csv: "評価内容ダウンロード",
    day_of_birth: "生年月日",
    label_zoom_account: "Zoomアカウントのメールアドレス",
    status_active: "活動中",
    status_inactive: "活動終了",
    close_up: "閉じる",
    following_items_can_only_be_edited_by_the_administrator: "※以下は管理者のみ編集できる項目です。編集した値が優先表示されます。",
    remove_video: 'クリア',
    label_google_link: "Googleカレンダーを連携します。\n よろしいですか？",
    label_google_unlink: "Googleカレンダーを連携解除します。\n 解除するとカレンダー管理から \n 連携されたイベントが削除されます。 \n よろしいですか？",
    label_google_retry_link: "Googleカレンダーを再連携します。\n 再連携するアカウントは連携時と \n 同じアカウントを指定してください。\n よろしいですか？",
    label_google_unlink_button: "解除する",
    label_google_retry_link_button: "再連携する",
}
